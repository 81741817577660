<template>
	<v-card :loading="Object.keys(data).length == 0">
		<v-container fluid>
			<v-row>
				<v-col cols="12" md="10">
					<!-- <v-subheader>Datos</v-subheader>
					<v-divider class="mb-1" />-->
					<div class="d-flex flex-wrap" style="gap: 10px">
						<v-dialog max-width="1400px" width="100%">
							<template v-slot:activator="{ on, attrs }">
								<v-btn :loading="Object.keys(data).length == 0" color="primary" outlined v-on="on" v-bind="attrs">
									<v-icon left>mdi-file-document-edit-outline</v-icon>Ver el contrato
								</v-btn>
							</template>
							<DetallesContrato
								:_codigoContrato="data.CodigoContrato"
								:_idContrato="data.IdContrato"
								class="pa-0"
							/>
						</v-dialog>
						<v-dialog max-width="1400px" width="100%">
							<template v-slot:activator="{ on, attrs }">
								<v-btn :loading="Object.keys(data).length == 0" color="primary" outlined v-on="on" v-bind="attrs">
									<v-icon left>mdi-account</v-icon>Ver el cliente
								</v-btn>
							</template>
							<DetallesCliente :_idCliente="data.IdCliente" class="pa-0" />
						</v-dialog>
						<v-btn :loading="Object.keys(data).length == 0" color="primary" outlined @click="verPDFfactura(data.IdDocumento)">
							<v-icon left>mdi-file-document-edit-outline</v-icon>Ver PDF
						</v-btn>

						<v-btn
							v-if="$root.acceso('CONTROL_IMPAGADOS') && $root.acceso('CONTROL_MONITORIO_IMPAGADOS')"
							color="warning"
							:outlined="!Number(data.dptoJuridico)"
							:loading="Object.keys(data).length == 0"
							@click="enviarMonitorio"
						>Envio dpto. Jurídico</v-btn>

						<!-- <v-dialog max-width="500px" width="100%">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-if="$root.acceso('CONTROL_IMPAGADOS') && $root.acceso('CONTROL_MONITORIO_IMPAGADOS')"
									color="warning"
									:outlined="data.cesce_temp == null"
									:loading="Object.keys(data).length == 0"
									v-on="on" v-bind="attrs"
								>Envio cesce</v-btn>
							</template>
							<v-card class="pa-3">
								<v-text-field dense hide-details label="Valor" filled v-model="valorCesceTemp" />
							<v-card-actions>
								<v-spacer />
								<v-btn color="primary" @click="enviarCesce">
								<v-icon left>mdi-content-save</v-icon>
								Guardar</v-btn>
							</v-card-actions>
							</v-card>
						</v-dialog> -->

						<v-dialog v-model="linkPagoTarjetaModel" max-width="900px" width="100%">
							<template v-slot:activator="{ on, attrs }">
								<v-btn :loading="Object.keys(data).length == 0" color="primary" outlined v-on="on" v-bind="attrs">
									<v-icon left>mdi-credit-card-outline</v-icon>Link pago tarjeta
								</v-btn>
							</template>
							<LinkPagoTarjeta @close="linkPagoTarjetaModel = false" :impagados="[data]" class="pa-0" />
						</v-dialog>

						<v-dialog v-if="$root.acceso('CONTROL_IMPAGADOS') && $root.acceso('CONTROL_CORTES_IMPAGADOS')" v-model="fechaCorteEstimadaDialog" width="unset">
							<template v-slot:activator="{on, attrs}">
								<v-btn @click.stop="fechaCorteEstimadaDialog = true" outlined color="primary" v-on="on" v-bind="attrs">
									<v-icon left>mdi-content-cut</v-icon>
									Fecha corte estimada <small>~{{ parseDate(data.fechaCorteEstimada) }}</small>
								</v-btn>
							</template>
							<v-card>
								<v-date-picker :min="new Date().toISOString()" v-model="fechaCorteEstimada" color="" landscape />
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn text @click.stop="fechaCorteEstimada = null;guardarCorteEstimado()" > Eliminar </v-btn>
									<v-btn text @click.stop="guardarCorteEstimado" > Guardar </v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>

					</div>
				</v-col>

				<v-col cols="6" md="2" class="d-flex justify-center">
					<div class="d-flex" v-if="data.NumeroFraccion">
						<v-icon
							v-for="i in Number(data.NFracciones)"
							:key="i"
							:color="['primary darken-3', 'primary darken-2', 'primary darken-1', 'primary'][i - 1]"
						>{{ Number(data.NumeroFraccion) >= i ? 'mdi-circle' : 'mdi-circle-outline' }}</v-icon>
					</div>
					<!-- <status-chip pill :label="false" :value="'Luz'" ></status-chip> -->
				</v-col>

				<v-col cols="12" class="d-flex align-center" style="gap: 10px">
					<status-chip pill :label="false" :value="data.entorno" ></status-chip>
					<v-alert v-if="data.motivoImpagado" dense type="info" text class="d-inline-flex ma-0">
						<span class="font-weight-bold">Motivo:</span>
						{{ data.motivoImpagado }}
					</v-alert>

					<v-alert v-if="data.amenazaCorte" dense type="error" text class="d-inline-flex ma-0">
						<span class="font-weight-bold">Corte:</span>
						{{ data.corteEn }}
					</v-alert>

				</v-col>
			</v-row>
			<v-row>

				<v-col v-if="data.mensajes != null" cols="12" md="6">
					<tarjeta-datos header icon="currency-eur-off">
						<template v-slot:titulo>
							Mensajes Impagado
							<v-btn class="ml-auto" small color="primary" @click.stop="getInfo">
								<v-icon left>mdi-refresh</v-icon>Recargar
							</v-btn>
						</template>
						<Chat :messages="data.mensajes" key-name="IdCarteraCobro" :item-key="data.IdCarteraCobro" @reload="getInfo" />
					</tarjeta-datos>
				</v-col>

				<v-col v-if="data.mensajesCliente != null" cols="12" md="6">
					<tarjeta-datos header icon="currency-eur-off">
						<template v-slot:titulo>
							Notas Cliente
							<v-btn class="ml-auto" small color="primary" @click.stop="getInfo">
								<v-icon left>mdi-refresh</v-icon>Recargar
							</v-btn>
						</template>
						<Chat :messages="data.mensajesCliente" key-name="IdCliente" :item-key="data.IdCliente" @reload="getInfo" />
					</tarjeta-datos>
				</v-col>

				<v-col cols="12" md="3">
					<tarjeta-datos header titulo="Datos de contacto" icon="phone">
						<template v-for="(cont, index) in contactos">
							<aux-input :key="index" :title="cont.nombre">
								{{cont.valor}}
								<v-dialog v-if="cont.link" max-width="400px">
									<template v-slot:activator="{attrs, on}">
										<v-btn class="ml-2" small icon v-bind="attrs" v-on="on">
											<v-icon small>mdi-qrcode-scan</v-icon>
										</v-btn>
									</template>
									<v-card class="pa-4 d-flex flex-column align-center">
										<qrcode-vue :value="cont.link" :size="300" ></qrcode-vue>
										<br />
										{{ cont.valor }}
									</v-card>
								</v-dialog>
							</aux-input>
							<v-divider :key="index + 'a'" />
						</template>
					</tarjeta-datos>
				</v-col>

				<v-col cols="12" md="6" v-if="$root.acceso('CONTROL_IMPAGADOS')">
					<!-- <tarjeta-datos
						header
						titulo="Historial estados"
						icon="format-list-bulleted"
						:datos="historialEstados"
					/>-->
					<tarjeta-datos header titulo="Historial estados" icon="format-list-bulleted">
						<v-simple-table>
							<thead>
								<tr>
									<th>Estado</th>
									<th>Fecha Cambio</th>
									<th>Fecha Vencimiento</th>
									<th>Usuario</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(h, index) in data.historialEstados" :key="index">
									<td>
										<status-chip :value="h.estado" />
									</td>
									<td>{{ parseDate(h.fechaCambio, false, true, true) }}</td>
									<td>{{ parseDate(h.fechaVencimiento, false, true, true) }}</td>
									<td>
										{{ h.usuario }}
										<v-icon
											v-if="h.idUsuario == $store.getters.getDecodedJwtEmpresa.idUsuario"
											rigth
											color="primary"
											small
										>mdi-account</v-icon>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</tarjeta-datos>
					<div class="mt-2">
						<div class="d-flex flex-column">
							<v-select
								clearable
								filled
								hide-details
								:items="data.estados"
								item-text="estado"
								return-object
								v-model="nuevoEstado.estado"
								placeholder="Seleccionar nuevo estado"
								@change="$delete(nuevoEstado, 'vencimiento')"
							>
								<template v-slot:selection="{ item }">
									<status-chip :value="item.estado" />
								</template>

								<template v-slot:item="{ item, }">
									<status-chip :value="item.estado" />
								</template>
							</v-select>

							<template v-if="nuevoEstado.estado && Number(nuevoEstado.estado.requiereVencimiento)">
								<v-subheader>Fecha vencimiento</v-subheader>
								<v-divider />
								<v-date-picker
									class="mt-1"
									full-width
									landscape
									v-model="nuevoEstado.vencimiento"
									dense
									:min="[new Date().getFullYear(), ('00' + (new Date().getMonth() + 1)).slice(-2), ('00' + new Date().getDate()).slice(-2)].join('-')"
								></v-date-picker>
							</template>
						</div>
						<div class="d-flex">
							<v-spacer />
							<v-btn @click="setEstado" class="mt-2" color="primary">Guardar nuevo Estado</v-btn>
						</div>
					</div>
				</v-col>

				<v-col cols="12" md="3">
					<tarjeta-datos
						header
						titulo="Comercial"
						icon="account-tie"
						:datos="[
							{ nombre: 'Canal/Agente', valor: data.CanalAgente },
							{ nombre: 'SubAgente', valor: data.SubAgente },
							{ nombre: 'Correo', valor: data.Correo },
							{ nombre: 'Telefono', valor: data.telefono },
							{ nombre: 'Portal', valor: data.empresa ? data.empresa.toUpperCase() : '' }
						]"
					/>
				</v-col>

				<v-col cols="12" md="3">
					<tarjeta-datos header titulo="Envío de cartas" icon="email-send">
						<v-simple-table>
							<thead>
								<tr>
									<th>Carta</th>
									<th>Envío previsto</th>
									<th>Fecha Envío</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1ª</td>
									<td>{{ parseDate(data.envioCarta1) }}</td>
									<td>{{ parseDate(data.carta1) }}</td>
								</tr>
								<tr>
									<td>2ª</td>
									<td>{{ parseDate(data.envioCarta2) }}</td>
									<td>{{ parseDate(data.carta2) }}</td>
								</tr>
								<tr>
									<td>Corte</td>
									<td>{{ parseDate(data.envioCarta3) }}</td>
									<td>{{ parseDate(data.carta3) }}</td>
								</tr>
							</tbody>
						</v-simple-table>
					</tarjeta-datos>
				</v-col>

				<v-col cols="12" md="3">
					<tarjeta-datos
						:datos="[
							{ nombre: 'Factura', valor: [data.SerieFacturaRoot, data.NumeroFacturaRoot].join(' ') },
							{ nombre: 'Fecha vencimiento', valor: parseDate(data.FechaVencimiento) },
							{ nombre: 'Días vencido', valor: data.diasDesdeVencimiento },
							{ nombre: 'Fecha impagado', valor: parseDate(data.FechaImpagado) },
							{ nombre: 'Días impagado', valor: data.diasDesdeImpagado },
						]"
					/>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import { parseDate, decode64, downloadFile } from "@/utils/index.js";
import { phone } from "@/utils/validations.js";
import { parseImpagados } from '../services/parseImpagados.js'
import QrcodeVue from 'qrcode.vue'

export default {
	name: 'detalles-impagados',
	components: {
		TextEditor: () => import("@/components/TextEditor.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
		DetallesContrato: () => import("@/modules/contratos/views/DetallesContrato.vue"),
		DetallesCliente: () => import("@/modules/clientes/views/DetallesCliente.vue"),
		TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
		AuxInput: () => import("@/components/AuxInput.vue"),
		TableButton: () => import("@/components/TableButton.vue"),
		DragAndDrop: () => import("@/components/DragAndDrop.vue"),
		MensajeTimeline: () => import("@/components/MensajeTimeline.vue"),
		NuevoMensajeTimeline: () => import("@/components/NuevoMensajeTimeline.vue"),
		LinkPagoTarjeta: () => import("../components/LinkStripe.vue"),
		QrcodeVue,

		Chat: () => import("@/components/Chat/Chat.vue"),
	},
	props: {
		IdCarteraCobro: String | Number,
	},
	data() {
		return {
			data: {},
			estado: "",
			interval: null,
			contactos: [],
			historialEstados: [],
			linkPagoTarjetaModel: false,
			nuevoEstado: {
				estado: null,
				vencimiento: null,
			},
			newMessageImp: {
				show: false,
				files: [],
				msg: null,
			},
			newMessage: {
				show: false,
				files: [],
				msg: null,
			},

			valorCesceTemp : null,
			fechaCorteEstimada: null,
			fechaCorteEstimadaDialog: false,
		};
	},
	methods: {
		parseDate,
		decode64,
		getInfo() {
			return new Promise(async resolve => {
				const { data } = await axios({
					url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
					method: 'GET',
					params: {
						token: this.$store.getters.getJwtEmpresa,
						IdCarteraCobro: this.IdCarteraCobro
					}
				});

				this.data = parseImpagados([data])[0];
				this.nuevoEstado = { }
				resolve();
			})
		},
		async setEstado() {
			await axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
				method: 'PUT',
				data: {
					IdCarteraCobro: this.IdCarteraCobro,
					nuevoEstado: this.nuevoEstado.estado.idEstadoImpagado,
					nuevoVencimiento: this.nuevoEstado.vencimiento ? parseDate(this.nuevoEstado.vencimiento, true) : null,
					token: this.$store.getters.getJwtEmpresa
				}
			})

			this.getInfo();
			this.$emit('reload');
		},
		send() {
			const formData = new FormData();
			formData.append('token', this.$store.getters.getJwtEmpresa);
			formData.append('IdCarteraCobro', this.IdCarteraCobro);
			formData.append('mensaje', this.newMessageImp.msg);
			formData.append('tipo', this.$root.acceso('CONTROL_IMPAGADOS') ? this.newMessageImp.tipo : 'comercial');
			for (let i = 0; i < this.newMessageImp.files.length; i++) {
				formData.append("documentos[]", this.newMessageImp.files[i]);
			}
			axios({
				method: "POST",
				url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
				data: formData,
			})
				.then(({ data }) => {
					this.newMessageImp.show = false;
					this.newMessageImp.msg = "";
					this.newMessageImp.files = [];
					this.getInfo();
				})
				.catch((err) => console.error(err));
		},
		sendNewMessage() {
			const formData = new FormData();
			formData.append('token', this.$store.getters.getJwtEmpresa);
			formData.append('IdCliente', this.data.IdCliente);
			formData.append('mensaje', this.newMessage.msg);
			formData.append('tipo', this.$root.acceso('CONTROL_IMPAGADOS') ? this.newMessage.tipo : 'comercial');
			for (let i = 0; i < this.newMessage.files.length; i++) {
				formData.append("documentos[]", this.newMessage.files[i]);
			}
			axios({
				method: "POST",
				url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
				data: formData,
			})
				.then(({ data }) => {
					this.newMessage.show = false;
					this.newMessage.msg = "";
					this.newMessage.files = [];
					this.getInfo();
				})
				.catch((err) => console.error(err));
		},
		verPDFfactura(idDocumento) {
			let str = this.$store.getters.getDatosEmpresa;
			const { IdUsuario } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;
			axios({
				method: "get",
				url: `${process.env.VUE_APP_API_URL}/FacturaVentaCabecera/FacturaPreviewPDF/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Documento/${idDocumento}`,
				responseType: "blob",
			})
				.then((res) => {
					let pdf = new File( [res.data], `factura_${new Date().getTime()}.pdf`, { type: "application/pdf", } );
					downloadFile(pdf, true);
				})
				.catch((err) => {
					this.$root.$emit("snack", "Ha ocurrido un error");
				});
		},
		async enviarMonitorio() {
			await axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/envio_monitorio.php`,
				method: 'POST',
				data: {
					IdCarteraCobro: this.IdCarteraCobro,
					token: this.$store.getters.getJwtEmpresa
				}
			})

			this.getInfo();
			this.$emit('reload');
		},
		async enviarCesce() {
			await axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/cesce/cesce_temp.php`,
				method: 'POST',
				data: {
					IdCliente: this.data.IdCliente,
					valor: this.valorCesceTemp,
					token: this.$store.getters.getJwtEmpresa
				}
			})

			this.getInfo();
			this.$emit('reload');
		},
		async guardarCorteEstimado() {
			await axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/corteEstimado.php`,
				method: 'POST',
				data: {
					IdContrato: this.data.IdContrato,
					fechaCorte: this.fechaCorteEstimada ? parseDate(this.fechaCorteEstimada, true) : null,
					token: this.$store.getters.getJwtEmpresa
				}
			})

			this.fechaCorteEstimadaDialog = false
			this.getInfo();
			this.$emit('reload');
		}
	},
	async mounted() {
		await this.getInfo();

		// this.interval = setInterval(() => {
		// 	this.getInfo();
		// }, 30 * 1000);

		this.contactos.push({ nombre: 'Nombre', valor: this.data.RazonSocialTitular || [this.data.NombreTitular, this.data.Apellido1Titular, this.data.Apellido2Titular].filter((i) => !!i).join(' ') },)

		this.data.contactos.forEach((c) => {
			const getTipo = (tipo) => {
				switch (tipo) {
					case 'M':
						return 'Móvil'
					case 'E':
						return 'Email'
					case 'F':
					case 'T':
						return 'Fijo'
					default:
						return '';
				}
			}
			
			if ( phone( c.Valor ) === true ) c.link = `tel:${c.Valor}`
			this.contactos.push({ nombre: getTipo(c.TipoContacto), valor: c.Valor, link: c.link })
		})
	},
	beforeDestroy() {
		// clearInterval(this.interval);
	},
};
</script>

<style>
.sticky {
	position: sticky;
	top: 0;
}
</style>